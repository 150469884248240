import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const apiRequest = async (method, endpoint, data=null) => {
  try {

    let session = Cookies.get('session') || "";
    
    let url = `${API_BASE_URL}${endpoint}`;
    
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session}` 
      }
    };

    if (method === "GET") {

      const response = await axios.get(url, config);
      return response.data;

    } else if (method === "POST") {

      const response = await axios.post(url, data, config);
      return response.data;

    } else {

      throw new Error("Invalid method");

    }

  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return { errors: [{ type: "general", msg: "Network error" }] };
    }
  }
};


export const getUserBySession = async () => {
  let response = await apiRequest("GET", "/auth");
  return response;
};

export const loginUserByCredentials = async (email, password) => {
  let response = await apiRequest("POST", "/auth", {email: email, password: password});
  return response;
};

export const getUserPhones = async (page, pageSize, search) => {
  let response = await apiRequest("POST", "/user/phones", { page: page, pageSize: pageSize, search: search});
  return response;
};

export const getUserMessages = async (page, pageSize, search, fromDate, tillDate) => {
  let response = await apiRequest("POST", "/user/messages", { page: page, pageSize: pageSize, search: search, fromDate:fromDate, tillDate:tillDate});
  return response;
};