import * as React from 'react';
import Header from '../../components/header';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { CopyBlock, dracula } from "react-code-blocks";
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";
import Navigation from '../../components/navigation';

function SettingsTabs({ tab, user }) {

    if( ! user ) {
        return (<></>);
    }

    let code = "";
    code+= `curl -X GET "https://teamcluster.click/phones/{phone_number}/sms/{test_id}" -H "AUTH: {api_key}"`
    code+= "\n#or\n";
    code+= `curl -X GET "https://teamcluster.click/phones/{phone_number}/sms/{test_id}?AUTH={api_key}"`

    let example = "";
    example+= `curl -X GET "https://teamcluster.click/phones/992789580367/sms/15"`
    example+= ` -H "AUTH: `
    example+= user.api_key
    example+= `"`
    example+= "\n#or\n";
    example+= `curl -X GET "https://teamcluster.click/phones/992789580367/sms/15?AUTH=`
    example+= user.api_key
    example+= `"`

    let response_0 = "#AUTH header or AUTH parameter is missing.";
    response_0+="\n"
    response_0+= "#Empty response";

    let response_1 = "#No messages for this number in 300 seconds.";
    response_1+="\n"
    response_1+= "No messages.";

    let response_2 = "#{phone_number} or {test_id} parameters in request are missing.";
    response_2+="\n"
    response_2+= "Phone or test id is missing.";

    let response_3 = "#Used number in request is not your number.";
    response_3+="\n"
    response_3+= "You don't have access to this phone number.";

    let response_4 = "#Success, array of messages found.";
    response_4+="\n"
    response_4+= `[
        {
            "id": "27a788b576da712c7bb3c7d1347621d6",
            "text": "132 849 is your Instagram code. Don't share it. #ig",
            "service": "32665",
            "datetime": "2024-04-28 05:38:56"
        }
    ]`;

    switch(tab) {
        case 0:
            return (
                <>
                    <Box className="api">

                        <p className="bold">Your API KEY:</p><br/>
                        <CopyBlock language="bash" text={user.api_key} showLineNumbers={false} theme={dracula} wrapLines={true} codeBlock/>
                        <br/>

                        <p className="bold">Send GET request:</p><br/>
                        <CopyBlock language="bash" text={code} showLineNumbers={false} theme={dracula} wrapLines={true} codeBlock/>
                        <br/>

                        <p className="bold">Example:</p><br/>
                        <CopyBlock language="bash" text={example} showLineNumbers={false} theme={dracula} wrapLines={true} codeBlock/>
                        <br/>

                        <p className="bold">Possible responses:</p><br/>
                        <CopyBlock language="bash" text={response_0} showLineNumbers={false} theme={dracula} wrapLines={true} codeBlock/>
                        <br/>
                        <CopyBlock language="bash" text={response_1} showLineNumbers={false} theme={dracula} wrapLines={true} codeBlock/>
                        <br/>
                        <CopyBlock language="bash" text={response_2} showLineNumbers={false} theme={dracula} wrapLines={true} codeBlock/>
                        <br/>
                        <CopyBlock language="bash" text={response_3} showLineNumbers={false} theme={dracula} wrapLines={true} codeBlock/>
                        <br/>
                        <CopyBlock language="bash" text={response_4} showLineNumbers={false} theme={dracula} wrapLines={true} codeBlock/>
                    </Box>
                </>
            );
        default:
            return null;
    }
}

export default function Settings() {

    const [tab, setTab] = React.useState(0);

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    const user = useSelector((state) => state.user.currentUser);
  
    return (
        <>
            <Header user={user} />
            <Navigation user={user} />
            <Box className="content">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tabs-list">
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="API" />
                    </Tabs>
                </Box>
                <Box className="tabs-content">
                    <SettingsTabs tab={tab} user={user} />
                </Box>
            </Box>
        </>
    );
}