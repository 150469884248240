import * as React from 'react';
import Header from '../components/header';
import { useSelector } from "react-redux";
import Navigation from '../components/navigation';

export default function Home() {

    const user = useSelector((state) => state.user.currentUser);

    return (
        <>
            <Header user={user} />
            <Navigation user={user} />
            <div className="content">
                <h1 style={{ textAlign: 'center' }}><br/><br/>Comming soon...</h1> 
            </div>
        </>
    );
}