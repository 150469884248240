import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Modal from '@mui/material/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate} from 'react-router-dom';
import { Typography } from '@mui/material';
import { loginUserByCredentials } from '../../api/user';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { LoginIcon, ErrorIcon } from '../../components/icons';

export default function Auth() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '', credentials: '', general: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const nextPath = (path) => {
        navigate(path);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const login = async () => {

        setIsLoading(true);

        if (validate()) {
            
            await loginUserByCredentials(email, password).then(data => {

                console.log(data)

                if( ! data.errors ) {

                    Cookies.set('session', data.data.session)
                    nextPath('/')

                } else {

                    let newErrors = { email: '', password: '', credentials: '', general: '' };

                    console.log(data.errors)
              
                    data.errors.forEach(err => {
                      if (err.type === 'field' || err.type === 'local') {
                        newErrors[err.path] = err.msg;
                      } else {
                        newErrors.general = err.msg;
                        setOpen(true);
                      }
                    });

                    console.log(newErrors)
              
                    setErrors(newErrors);

                }

            })
            .catch(error => {
                setOpen(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    };
    
    const validate = () => {

        let tempErrors = { email: '', password: '', credentials: '', general: '' };
        let isValid = true;

        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            tempErrors.email = 'Email is not valid.';
            isValid = false;
        }
        if (!password || password.length < 5) {
            tempErrors.password = 'Password must be at least 6 characters long.';
            isValid = false;
        }

        setErrors(tempErrors);

        if (!isValid) {
            setTimeout(() => {
              setErrors({ email: '', password: '', credentials: '', general: '' });
              setOpen(false);
            }, 5000);
        }

        return isValid;
    };
    
    const user = useSelector((state) => state.user.currentUser);

    if( user ) {
        nextPath('/')
        return null;
    }

    return (
        <div className="auth">
            <div className="flex-content">
                <div className="flex-area">
                    <div className="flex-item">
                        <div className="login">
                            <Stack spacing={3}> 
                                <FormControl sx={{ m: 1, width: '100%' }} error={Boolean(errors.email)}>
                                    <InputLabel htmlFor="email" className="label" variant="standard">Email</InputLabel>
                                    <Input id="email" 
                                        type="text" 
                                        className="email" 
                                        variant="standard"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onFocus={() => setErrors({ ...errors, email: '', credentials: '' })}
                                        aria-describedby="email-error-text" 
                                    />
                                    <FormHelperText id="email-error-text" className="error">{errors.email}</FormHelperText>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: '100%' }} error={Boolean(errors.password)}>
                                    <InputLabel htmlFor="password" className="label" variant="standard">Password</InputLabel>
                                    <Input
                                        id="password"
                                        className="password"
                                        type={showPassword ? 'text' : 'password'}
                                        variant="standard"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onFocus={() => setErrors({ ...errors, password: '', credentials: '' })}
                                        endAdornment={
                                            <InputAdornment position="end">
                                            <IconButton                                 
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        }
                                        aria-describedby="password-error-text"
                                        />
                                    <FormHelperText id="password-error-text" className="error">{errors.password}</FormHelperText>
                                </FormControl>
                            </Stack>
                            <FormControl sx={{ m: 1, width: '100%' }} error={Boolean(errors.credentials)}>
                                <FormHelperText id="credentials-error-text" className="error creds">{errors.credentials}</FormHelperText>
                            </FormControl>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={5}>
                                <Button variant="contained" 
                                    className="fixed"
                                    startIcon={isLoading ? <CircularProgress size={24} color="secondary" /> : <LoginIcon />} 
                                    onClick={login}
                                    disabled={isLoading}
                                >
                                    Login
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <div className="popup">
                    <ErrorIcon/>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        General error
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {errors.general}
                    </Typography>
                </div>
            </Modal>
        </div>
    );
}