import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import { AuthIcon } from "../components/icons"

export default function Header({ user }) {
    const navigate = useNavigate();  // Always call hooks at the top level

    function nextPath(path) {
        navigate(path);
    }

    if (!user) {
        return null;  // Use null for no output instead of an empty fragment
    }

    return (
        <div className="header">
            <div className="item">
                <div className="logo">SMS MONITOR</div>
            </div>
            <div className="item auth">
                <IconButton onClick={() => nextPath('/')}>
                    <p className="name">{user.email}</p>
                    <AuthIcon />
                </IconButton>
            </div>
        </div>
    );
}
