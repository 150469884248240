import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { getUserPhones } from '../../api/user';
import { useState, useEffect } from 'react';
import { ErrorIcon, SearchIcon } from '../../components/icons';
import { useSelector } from "react-redux";
import Header from '../../components/header';
import Navigation from '../../components/navigation';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';


const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'country', headerName: 'Country', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 250 },
  ];

function NumbersTabs({ tab, user, phones, pagination, setPagination, search, setSearch, isLoading }) {

    if( ! user ) {
        return (<></>);
    }

    switch(tab) {
        case 0:

            return (
                <>
                    <Box className="phones">
                        <div className="filters">
                            <Stack direction="row" justifyContent="left" alignItems="center" spacing={0}>
                                <TextField id="outlined-basic" label="Search by phone" variant="outlined" size="small" className="search" defaultValue={search} 
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <IconButton onClick={() => { setSearch(search) }}>
                                    {isLoading ? <CircularProgress size={24} color="secondary" /> : <SearchIcon /> }
                                </IconButton>    
                            </Stack>
                        </div>
                        <div style={{ height: 'calc(100vh - 177px - 60px)', width: '100%' }} className="data">
                            <DataGrid rowHeight={30}
                                rows={isLoading || ! phones.data ? [] : phones.data}
                                columns={columns}
                                rowCount={isLoading || ! phones.total ? -1 : phones.total}
                                initialState={{
                                    pagination: {
                                        paginationModel: pagination,
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'id', sort: 'desc' }], // replace 'fieldName' with the name of your field and set 'sort' to 'desc' for descending order
                                    },
                                }}
                                pageSizeOptions={[50, 100]}
                                checkboxSelection={false} 
                                paginationMode="server"
                                onPaginationModelChange={(params) => {
                                    setPagination({page: (params.page + 1), pageSize: params.pageSize});
                                }}
                            />
                        </div>
                    </Box>
                </>
            );
        default:
            return null;
    }
}

export default function Numbers() {

    const [tab, setTab] = useState(0);
    const [phones, setPhones] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({ general: '' });
    const [pagination,setPagination] = useState({page: 1, pageSize: 50})
    const [search,setSearch] = useState('')

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const user = useSelector((state) => state.user.currentUser);

    useEffect(() => {

        console.log(pagination);

        async function getUserPhonesList() {

            setIsLoading(true);
    
            try {
                
                const data = await getUserPhones(pagination.page, pagination.pageSize, search);
                console.log(data);
    
                if( ! data.errors ) {
    
                    //console.log(data.data.phones)
                    setPhones(data.data)
    
                } else {
    
                    let newErrors = { general: '' };
    
                    console.log(data.errors)
                
                    data.errors.forEach(err => {
                        newErrors.general = err.msg;
                        setOpen(true);
                    });
    
                    console.log(newErrors)
                
                    setErrors(newErrors);
    
                }
                
            } catch (error) {
                console.log(error);
                setOpen(true);
            } finally {
                // Any cleanup actions if necessary
                setIsLoading(false);
            }
        }

        getUserPhonesList();

    }, [pagination, search]);
  
    return (
        <>
            <Header user={user} />
            <Navigation user={user} />
            <Box className="content">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tabs-list">
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Numbers" />
                    </Tabs>
                </Box>
                <Box className="tabs-content">
                    <NumbersTabs tab={tab} user={user} phones={phones} pagination={pagination} setPagination={setPagination} search={search} setSearch={setSearch} isLoading={isLoading} />
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <div className="popup">
                    <ErrorIcon/>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        General error
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {errors.general}
                    </Typography>
                </div>
            </Modal>
        </>
    );
}