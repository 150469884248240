import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from "./pages/layout";
import Page404 from "./pages/404";
import Home from "./pages";
import Auth from "./pages/auth";
import Stats from "./pages/stats";
import Numbers from "./pages/numbers";
import Settings from "./pages/settings";

export default function App() {

    const router = createBrowserRouter([
        {
            element: <Layout />,
            errorElement: <Page404 />,
            children: [
               { path: "/", element: <Home /> },
                { path: "/auth", element: <Auth /> },  
                { path: "/stats", element: <Stats /> },
                { path: "/numbers", element: <Numbers />,},
                { path: "/settings", element: <Settings /> },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
}
