import { useNavigate } from "react-router-dom";
import { LogoutIcon, NumbersIcon, StatsIcon, DashboardIcon, SettingsIcon } from "./icons";
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export default function Navigation({ user }) {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState('');

    useEffect(() => {
        if (!user) {
            navigate('/auth'); // Redirect to login if no user, or simply hide navigation
        } else {
            switch (window.location.pathname) {
                case '/':
                    setSelectedItem('/');
                    break;
                case '/stats':
                    setSelectedItem('/stats');
                    break;
                case '/numbers':
                    setSelectedItem('/numbers');
                    break;
                case '/settings':
                    setSelectedItem('/settings');
                    break;
                default:
                    setSelectedItem('');
            }
        }
    }, [user, navigate]);

    function nextPath(path) {
        navigate(path);
        setSelectedItem(path);
    }

    function logout() {
        Cookies.set('session', '');
        window.location.reload(true); 
    }

    if (!user) {
        return null; // Render nothing if no user
    }

    return (
        <div className="menu">
            <Box sx={{ width: 186, height: '100%' }} role="presentation">
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => nextPath('/')} selected={selectedItem === '/'}>
                            <ListItemIcon className="ic-holder">
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" className="white" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => nextPath('/stats')} selected={selectedItem === '/stats'}>
                            <ListItemIcon className="ic-holder">
                                <StatsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Statistics" className="white" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => nextPath('/numbers')} selected={selectedItem === '/numbers'}>
                            <ListItemIcon className="ic-holder">
                                <NumbersIcon />
                            </ListItemIcon>
                            <ListItemText primary="Numbers" className="white" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => nextPath('/settings')} selected={selectedItem === '/settings'}>
                            <ListItemIcon className="ic-holder">
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Settings" className="white" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className="bottom">
                        <ListItemButton onClick={logout}>
                            <ListItemIcon className="ic-holder">
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" className="white" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </div>
    );
}