import { Outlet } from "react-router-dom";
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import { setUser } from "../store/actions/user";
import { getUserBySession } from "../api/user";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';

export default function Layout() { 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Memoizing nextPath to avoid creating a new function on every render
    const nextPath = useCallback((path) => {
        navigate(path);
    }, [navigate]); // Dependency on navigate to recreate the function if navigate changes

    useEffect(() => {
        async function getUserData() { 
            try {
                const data = await getUserBySession();
                if (!data.errors) {
                    const user = data.data.user;
                    if (user) {
                        Cookies.set('session', user.session);
                        dispatch(setUser(user));
                    }
                } else {
                    const path = location.pathname;
                    if (path !== '/auth' && !path.startsWith('/auth/')) {
                        nextPath("/auth");
                    }
                    dispatch(setUser(null));
                }
            } catch (error) {
                nextPath("/");
                dispatch(setUser(null));
            }
        }

        getUserData();
    }, [dispatch, location.pathname, nextPath]); // Ensuring all used values are included as dependencies

    return (
        <main className="main">     
            <Outlet />
        </main>
    );
}
