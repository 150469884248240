import * as React from 'react';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SearchIcon, ErrorIcon } from "../../components/icons"
import Header from '../../components/header';
import { getUserMessages } from '../../api/user';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Navigation from '../../components/navigation';
import TextField from '@mui/material/TextField';

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'sender', headerName: 'Sender', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'text', headerName: 'Text', width:500 },
    { field: 'delivered', headerName: 'Delivered', width: 200 },
  ];

function StatsTabs({ tab, user, messages, pagination, setPagination, search, setSearch, fromDate, setFromDate, tillDate, setTillDate, isLoading }) {

    if( ! user ) {
        return (<></>);
    }

    switch(tab) {
        case 0:

            const handleFromDateChange = (newValue) => {
                setFromDate(newValue);
                if (moment(tillDate).isBefore(newValue)) {
                    setTillDate(newValue);
                } else if (moment(tillDate).isAfter(moment(newValue).add(1, 'month'))) {
                    setTillDate(moment(newValue).add(1, 'month'));
                }
            };
             
            const handleTillDateChange = (newValue) => {
                setTillDate(newValue);
            };

            return (
                <>
                    <Box className="messages">
                        <div className="filters">
                            <Stack direction="row" justifyContent="left" alignItems="center" spacing={0}>
                                <TextField id="outlined-basic" label="Search by phone" variant="outlined" size="small" className="search" defaultValue={search} 
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <IconButton onClick={() => { setSearch(search) }}>
                                    {isLoading ? <CircularProgress size={24} color="secondary" /> : <SearchIcon /> }
                                </IconButton>    
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker onChange={handleFromDateChange} className="datepicker" />
                                </LocalizationProvider>
                                <p>&nbsp;&nbsp;-&nbsp;&nbsp;</p>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker onChange={handleTillDateChange} className="datepicker" />
                                </LocalizationProvider>
                                <p>&nbsp;&nbsp;max ( +1 month ) </p>
                            </Stack>
                        </div>
                        <div style={{ height: 'calc(100vh - 177px - 76px)', width: '100%' }} className="data">
                            <DataGrid rowHeight={25}
                                rows={isLoading || ! messages.data ? [] : messages.data}
                                columns={columns}
                                rowCount={isLoading || ! messages.total ? -1 : messages.total}
                                initialState={{
                                    pagination: {
                                        paginationModel: pagination,
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'id', sort: 'desc' }], // replace 'fieldName' with the name of your field and set 'sort' to 'desc' for descending order
                                    },
                                }}
                                pageSizeOptions={[50, 100]}
                                checkboxSelection={false}
                                paginationMode="server"
                                onPaginationModelChange={(params) => {
                                    setPagination({page: (params.page + 1), pageSize: params.pageSize});
                                }}
                            />
                        </div>
                    </Box>
                </>
            );
        default:
            return null;
    }
}

export default function Stats() {

    const [tab, setTab] = useState(0);
    const [messages, setMessages] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({ general: '' });
    const [pagination, setPagination] = useState({page: 1, pageSize: 50})

    let fromDateValue = moment().subtract(1, 'month').format("YYYY-MM-DD");
    let tillDateValue = moment().format("YYYY-MM-DD");

    const [fromDate, setFromDate] = useState(fromDateValue)
    const [tillDate, setTillDate] = useState(tillDateValue)
    
    const [search, setSearch] = useState('')

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const user = useSelector((state) => state.user.currentUser);

    useEffect(() => {

        console.log(pagination);

        async function getUserMessagesList() {

            setIsLoading(true);
    
            try {
                
                const data = await getUserMessages(pagination.page, pagination.pageSize, search, fromDate, tillDate);
                console.log(data);
    
                if( ! data.errors ) {
    
                    //console.log(data.data.phones)
                    setMessages(data.data)
    
                } else {
    
                    let newErrors = { general: '' };
    
                    console.log(data.errors)
                
                    data.errors.forEach(err => {
                        newErrors.general = err.msg;
                        setOpen(true);
                    });
    
                    console.log(newErrors)
                
                    setErrors(newErrors);
    
                }
                
            } catch (error) {
                console.log(error);
                setOpen(true);
            } finally {
                // Any cleanup actions if necessary
                setIsLoading(false);
            }
        }

        getUserMessagesList();

    }, [pagination, search, fromDate, tillDate]);
      
    return (
        <>
            <Header user={user} />
            <Navigation user={user} />
            <Box className="content">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tabs-list">
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Messages" />
                    </Tabs>
                </Box>
                <Box className="tabs-content">
                    <StatsTabs tab={tab} user={user} messages={messages} pagination={pagination} setPagination={setPagination} search={search} setSearch={setSearch} 
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        tillDate={tillDate}
                        setTillDate={setTillDate}
                        isLoading={isLoading} />
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <div className="popup">
                    <ErrorIcon/>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        General error
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {errors.general}
                    </Typography>
                </div>
            </Modal>
        </>
    );
}