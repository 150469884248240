import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../store/actions/user";
import { getUserBySession } from "../api/user";

export default function Page404() {

    const dispatch = useDispatch();

    useEffect(() => {
        
        async function getUserData() {
            
            await getUserBySession()
                .then((data) => {
            
                    console.log(data);
            
                    if (!data.errors) {
            
                        const user = data.data.user;
            
                        if (user !== null) {
                            dispatch(setUser(data.data.user));
                        }
                    } else {
                        dispatch(setUser(null));
                    }
                })
                .catch((error) => {
                    dispatch(setUser(null));
                });
        }

        getUserData();
        
    }, [dispatch]);
  
    return (
        <main>                
            <h1>404 Not found</h1>
        </main>
    )
}